
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import SymboleLogo from '@pixcap/ui-library/components/Assets/SymboleLogo.vue';

	@Component({
		name: 'Spinner',
		components: {
			SymboleLogo,
		},
	})
	export default class Spinner extends Vue {
		@Prop() readonly size: number;
		get width() {
			return this.size || 40;
		}
	}
