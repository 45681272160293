
	import { Vue, Component, Prop } from 'vue-property-decorator';

	import { EdgeHandler } from '@pixcap/ui-core/src/models/store/resizeable.interface';

	@Component({
		name: 'ResizeHandler',
	})
	export default class ResizeHandler extends Vue {
		@Prop() handler: EdgeHandler;
	}
