
	import { Vue, Component, Prop } from 'vue-property-decorator';

	import { CornerHandler } from '@pixcap/ui-core/src/models/store/resizeable.interface';

	@Component({
		name: 'CornerResizeHandler',
	})
	export default class CornerResizeHandler extends Vue {
		@Prop() handler: CornerHandler;
	}
